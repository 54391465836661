import React from 'react';
import { Alert } from 'reactstrap';
import classNames from 'classnames';

import Styles from './MigrationBanner.module.scss';

const MigrationBanner = ({ className }) => {
  const location = window.location.hostname;

  // Only show the banner for AWS deployments (these URLS contain 'digital')
  if (!location.includes('digital')) {
    return null;
  }

  return (
    <Alert className={classNames(className, Styles.alert)}>
      <p>
        Das LMA / GU Dashboard zieht in die Google Cloud und ist bereits jetzt unter folgendem Link erreichbar:{' '}
        <a href='https://last-mile-analytics.hc.de' className='alert-link' target='_blank'>
          last-mile-analytics.hc.de
        </a>
        . Vorrübergehend wird <i>last-mile-analytics.hermesgermany.digital</i> weiterhin nutzbar bleiben und zum{' '}
        <b>31.12.2024</b> abgeschaltet. <b>Bitte an die Lesezeichenaktualisierung denken!</b> Nutzereinstellungen (wie
        z.B. angelegte KPI Kacheln) werden mit Stand xx (tbd) einmalig übernommen und anschließend nicht zwischen neuer
        und alter URL synchronisiert.
      </p>
    </Alert>
  );
};

export default MigrationBanner;
